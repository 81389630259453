import { useProductSearch } from '@/app/components/context/product-search-context';
import { api } from '@/trpc/react';
import { Button, ButtonProps } from '@av/ui/button';
import {
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@av/ui/dropdown';
import { cn } from '@av/utils';
import { Heart } from 'lucide-react';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

export default function AddOrderItemListButton({
  productId,
  ...props
}: ButtonProps & { productId: number }) {
  const { orderLists, refetchOrderLists } = useProductSearch();
  const { getValues } = useFormContext<{ quantity: number }>();

  const createOrderListItem = api.orderListItem.create.useMutation();
  const deleteOrderListItem = api.orderListItem.delete.useMutation();

  const utils = api.useUtils();

  const onToggleFavorite = async (orderListId: number) => {
    const orderList = orderLists?.find((orderList) => orderList.id === orderListId);
    if (!orderList) return;

    const itemExists = orderList.items.some((item) => item.productId === productId);

    if (itemExists) {
      const item = orderList.items.find((item) => item.productId === productId);
      if (!item) return;
      await deleteOrderListItem.mutateAsync({
        id: item.id,
      });
    } else {
      await createOrderListItem.mutateAsync({
        productId,
        orderListId,
        quantity: getValues('quantity') || 1,
      });
    }
    utils.orderList.invalidate();
    refetchOrderLists?.();
  };

  const isFavorite = useMemo(
    () =>
      orderLists?.some((orderList) => orderList.items.some((item) => item.productId === productId)),
    [orderLists, productId]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={!orderLists}>
        <Button
          variant="secondary"
          type="button"
          size="icon"
          aria-label="Toevoegen aan bestellijst"
          {...props}
        >
          <Heart className={cn('size-5 min-w-5', isFavorite ? 'text-primary fill-primary' : '')} />
          <span className="sr-only">Toevoegen</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="z-[99999] w-64" align="end">
        {orderLists?.length ? (
          orderLists.map((orderList) => (
            <DropdownMenuItem
              className="h-10 gap-2"
              key={orderList.id}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onToggleFavorite(orderList.id);
              }}
            >
              <Heart
                className={cn(
                  'size-4 flex-shrink-0',
                  orderList.items.some((item) => item.productId === productId)
                    ? 'text-primary fill-primary'
                    : 'text-muted fill-muted'
                )}
              />
              <span className="truncate whitespace-nowrap">{orderList.title}</span>
              <div className="ml-auto flex pr-1">
                {orderList.items
                  .sort((a) => (a.productId === productId ? -1 : 1))
                  .slice(0, orderList.items.length > 4 ? 3 : orderList.items.length)
                  .map(
                    (item, i) =>
                      !!item.product.thumbnailImage && (
                        <div key={item.id} className="max-w-5" style={{ zIndex: 1000 - i }}>
                          <img
                            src={item.product.thumbnailImage}
                            alt={item.product.title}
                            className={cn(
                              'size-6 bg-white min-w-[1.5rem] object-contain rounded-full ring-2 ring-offset-2 ring-white',
                              orderList.items.some((item) => item.productId === productId) &&
                                item.productId === productId
                                ? 'ring-offset-primary'
                                : 'ring-offset-muted'
                            )}
                          />
                        </div>
                      )
                  )}
                {orderList.items.length > 4 && (
                  <div className="max-w-5">
                    <div className="flex size-6 min-w-6 items-center justify-center rounded-full bg-primary/10 text-[10px] ring-2 ring-muted ring-offset-2">
                      +
                      {orderList.items.length -
                        (orderList.items.length > 4 ? 3 : orderList.items.length)}
                    </div>
                  </div>
                )}
              </div>
            </DropdownMenuItem>
          ))
        ) : (
          <DropdownMenuLabel>Bestellijsten aan het laden</DropdownMenuLabel>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
