'use client';

import React, { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { api } from '@/trpc/react';
import { useRouter } from 'next/navigation';
import { AccessoriesModal, AccessoryState, ProductState } from './accesories-modal';
import { useCart } from '../cart/cart-context';
import { Product } from '@av/database';
import { notifyError } from '@av/bugsnag';
import { toast } from 'react-hot-toast';
import { validateQuantity } from '@/lib/validate-quantity';
import { useSession } from 'next-auth/react';

// Define the context type
export interface AccessoriesContextType {
  /* eslint-disable no-unused-vars */
  productAccessoriesHandler: (
    state: { product: Product; quantity: number },
    cb?: () => void
  ) => Promise<void>;
}

// Create the context with the correct type
export const AccessoriesContext = createContext<AccessoriesContextType>({
  productAccessoriesHandler: async () => {},
});

export const useAccessories = () => useContext<AccessoriesContextType>(AccessoriesContext);

const AccessoriesProvider = ({ children }: { children: ReactNode }) => {
  const [showProductState, setShowProductState] = useState<
    (AccessoryState & { cb?: () => void }) | null
  >(null);

  const utils = api.useUtils();
  const router = useRouter();
  const { openCart } = useCart();
  const { update } = useSession();

  const checkAccessories = api.product.checkAccessories.useMutation();
  const createCartItem = api.cartItem.create.useMutation();

  const onSubmit = useCallback(
    async (productState: ProductState[]) => {
      try {
        await Promise.all(productState.map((p) => createCartItem.mutateAsync(p)));
        await utils.cartItem.invalidate();
        router.refresh();
        update();
        setShowProductState(null);
        openCart();
      } catch (e) {
        notifyError(e, 'Fout bij het indienen van producten');
        toast.error('Toevoegen mislukt. Probeer het opnieuw of neem contact op.');
      }
    },
    [createCartItem, openCart, router, utils.cartItem]
  );

  const productAccessoriesHandler = useCallback(
    async (state: { product: Product; quantity: number }, cb?: () => void) => {
      const accessories = await checkAccessories.mutateAsync(state.product.id);

      state.quantity = validateQuantity(
        { minQuantity: state.product.minQuantity || 1, step: state.product.step || 1 },
        state.quantity
      );

      if (!accessories.length) {
        await onSubmit([{ productId: state.product.id, quantity: state.quantity }]);
        cb?.();

        return;
      }

      setShowProductState({ ...state, accessories, productId: state.product.id, cb });
    },
    [checkAccessories, onSubmit]
  );

  const store = useMemo(
    () => ({
      productAccessoriesHandler,
    }),
    [productAccessoriesHandler]
  );

  return (
    <AccessoriesContext.Provider value={store}>
      {showProductState && (
        <AccessoriesModal
          isOpen={!!showProductState}
          onClose={() => setShowProductState(null)}
          state={showProductState}
          addToCart={onSubmit}
        />
      )}
      {children}
    </AccessoriesContext.Provider>
  );
};

export default AccessoriesProvider;
