import toast from 'react-hot-toast';

export const validateQuantity = (
  product: { minQuantity: number; step: number },
  quantity: number
): number => {
  const { minQuantity, step } = product;

  if (quantity < (minQuantity || 1)) {
    quantity = minQuantity || 1;
    toast(`Het minimale aantal is ${quantity}`);

    return quantity;
  }

  if (step && quantity % step !== 0) {
    const roundedQuantity = Math.ceil(quantity / step) * step;
    quantity = roundedQuantity;
    toast(`Je kunt dit artikel bestellen per ${step}. Aantal aangepast naar ${quantity}`);

    return quantity;
  }

  return quantity;
};
